(function() {
  
  var save = function(key, value) { localStorage.setItem(key, value); };
  var get = function(key) { return localStorage.getItem(key); };
  var remove = function(key) { localStorage.removeItem(key); };
  
  if (typeof(localStorage) === 'undefined') {

    save = function(key, value) {  };
    get = function(key) { return null; };
    remove = function(key) {  };
  }

  var savejson = function(key, value) {
    BS.base.storage.save(key, JSON.stringify(value));
  };
  var getjson = function(key, value) {
    return JSON.parse(BS.base.storage.get(key));
  };

  return {
    save: save,
    get: get,
    remove: remove,
    savejson: savejson,
    getjson: getjson
  }
})();