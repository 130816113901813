(function() {

  var __render = function(mode) {
    
    switch(mode){
    case 'loading':
      BS.base.modal.showLoading(true, gettext('bet slips'));
      break;
    case 'app':
      BS.base.modal.showTransactions(__transactions);
      break;
    }
  };

  var __common_get = BS.base.user_transactions.get;
  var get = function(page, filter) {
    __transactions = null;

    __render('loading');
    __common_get(page, filter,
      function(data) {

        __transactions = data;
        __render('app');
      },
      function(data) {

        var err = textStatus + ", " + error;
        console.error( "Request Failed: " + err );
        BS.base.modal.showDefault(gettext('Fail to communicate with server'));
        __render('app');
      },
      function(data) {}
    );
  };

  var __transactions = null;

  return {
    get: get
  };
})();