(function() {

  var __API = '/api/v1/bonus/';
  var state = {
    requested: 0,
    requesting: false,
    failed: false
  };

  var __post = function(data) {

    BS.base.bonus.state.requesting = true;
    BS.base.bonus.state.failed = false;
    $.ajax({
      type: 'POST',
      url: __API,
      data: { data: JSON.stringify(data) },
      headers: { 'X-CSRFToken': BS.base.csrftoken },
      success: function(data) {

        BS.base.modal.showDefault(data.message);
        BS.base.modal.timer(5000);
      },
      error: function(data) {
        
        if (data.responseJSON && data.responseJSON.error) {

          BS.base.modal.showDefault(data.responseJSON.error);
          BS.base.modal.timer(5000);
        } else {
          
          BS.base.modal.showDefault(gettext('Fail to communicate with server'));
          BS.base.modal.timer(5000);
        }
      },
      // complete: function(data) {
      // },
    }); 
  };

  var __get = function(successCallback, errorCallback, completeCallback) {

    BS.base.bonus.state.requesting = true;
    BS.base.bonus.state.failed = false;
    $.ajax({
      type: 'GET',
      url: __API,
      headers: { 'X-CSRFToken': BS.base.csrftoken },
      timeout: 3000,
      success: function(data) {

        BS.base.bonus.state.bonuses = data.bonuses;
        BS.base.bonus.state.bonuses.forEach(function(bonus) {

          bonus.endDate = new Date(bonus.endDate);
        });
        BS.base.bonus.state.requestables = data.requestables;
        BS.base.bonus.state.passive = data.passive;
        if (typeof successCallback !== 'undefined')
          successCallback(data);
      },
      error: function(data) {
        
        BS.base.bonus.state.bonuses = [];
        BS.base.bonus.state.failed = true;
        if (typeof errorCallback !== 'undefined')
          errorCallback(data);
      },
      complete: function(data) {
        
        // BS.base.bonus.state.requested = Date.now();
        BS.base.bonus.state.requesting = false;
        if (typeof completeCallback !== 'undefined')
          completeCallback(data);
        // __render();
      },
    }); 
  };

  var updateState = function(successCallback, errorCallback, completeCallback) {

    if (typeof(BS.base.state.user) !== "undefined" && !BS.base.state.user.anon && BS.base.state.user.type == "WU")
      if (!BS.base.bonus.state.requested || Date.now() - BS.base.bonus.state.requested > (5 * 60 * 1000))
       __get(successCallback, errorCallback, completeCallback);
    else if (typeof(BS.base.state.user) == "undefined" || BS.base.state.user.anon) {

      BS.base.modal.showDefault(gettext("Login first!"));
      BS.base.modal.timer(5000);
      BS.base.user.changePane('menu');
    }
  };

  var request = function(event, form) {

    event.preventDefault();

    var data = decodeURIComponent($(form).serialize());
    data = data.split('&').reduce(
    function ( accumulator, currentValue, currentIndex, array) {
      currentValue = currentValue.split('=');
      accumulator[currentValue[0]] = currentValue[1];
      return accumulator;
    }, {});

    __post(data);

    return false;
  };

  var showTerms = function(url) {

    if (/api\/v1\/documents/g.test(url)){

      BS.base.modal.showLoading(true);
      $.ajax({
        type: 'GET',
        url: url,
        headers: { 'X-CSRFToken': BS.base.csrftoken },
        timeout: 3000,
        success: function(data) {
  
          BS.base.modal.showLoading(false);
          BS.base.modal.showLetterCustom(gettext('Bonus Terms'), data);
        },
        error: function(data) {
          
          BS.base.modal.showLoading(false);
          BS.base.modal.showDefault(gettext('Fail to communicate with server'));
        }
      }); 
    } else {

      BS.base.modal.showDefault(gettext('Fail to communicate with server'));
    }
  };

  return {
    updateState: updateState,
    request: request,
    state: state,
    showTerms: showTerms
  };
})();