(function () {

  var __render = function() {

    BS.base.user_menu.update();
    BS.base.breadcrumbs.update();

    // $('.password:not(.sp-process)').password({
    //   eyeClass: '',
    //   eyeOpenClass: 'icmn-eye',
    //   eyeCloseClass: 'icmn-eye-blocked'
    // });
    // $('.password:not(.sp-process)').addClass('sp-process');
  };

  var __common_update = BS.base.header.update;
  var update = function(msgs) {

    __common_update(msgs);
    if(typeof(msgs) === 'undefined') {
      __render();
    } else if(msgs instanceof Array) {
      if(msgs[0] == 'BASE_PANE' || msgs[0] == 'USER') {

        __render();
      } else if (msgs[0] == 'BASE-THEME'){

        __render();
        var totopButton = $('#to-top-button .BSi-base');
        totopButton.attr('class', totopButton.attr('class').replace(/(BSi-Icon_Top_)Theme\d/g, '$1' + global_settings.base_themes.current))
      }
    }
  };

  return Object.assign(BS.base.header, {
    update: update,
  });
})();
