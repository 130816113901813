(function() {
  var moneyInput = function(element) {
    switch (global_settings.flavor) {
      case "PY":
        element.value = parseFloat("0" + element.value.replace(/\D/g, "")).toFixed(0);
      default:
        element.value = (parseFloat("0" + element.value.replace(/\D/g, "")) / 100).toFixed(2);
    }
  };

  return {
    moneyInput: moneyInput
  };
})();
