(function () {

  var __tempCacheDocument = {};
  var __getDocument = function(content) {

    var TITLE = {
      rules: gettext('Bet Rules'),
      contact: gettext('Contact'),
      faq: gettext('FAQ'),
      policies: gettext('Privacy Policy'),
      responsibility: gettext('Responsible Gaming'),
      terms: gettext('Terms and Conditions')
    };
    var title = TITLE[content];

    if (content in __tempCacheDocument && (Date.now() - __tempCacheDocument[content].timestamp) < (15 * 60 * 1000) ) {

      BS.base.modal.showLetterCustom(title, __tempCacheDocument[content].cached);
      return;
    }

    BS.base.modal.showLoading(true);
    $.ajax({
      type: 'GET',
      url: global_settings.apis.documents + content, // global_settings.documents[content],
      headers: { 'X-CSRFToken': BS.base.csrftoken },
      timeout: 3000,
      success: function(data) {

        BS.base.modal.showLoading(false);
        BS.base.modal.showLetterCustom(title, data);
        __tempCacheDocument[content] = { cached: data, timestamp: Date.now() };
      },
      error: function(data) {
        
        BS.base.modal.showLoading(false);
        BS.base.modal.showDefault(gettext('Fail to communicate with server'));
      }
    }); 
  };

  var init = function(css) {
    this.element = $(css);
    this.element.modal({show: false, backdrop: 'static', keyboard: false});
    window.addEventListener('resize', this.fitModal);

    return function() {};
  };

  var element;           // Choosen element
  var loading = false;   // If its in loading modal
  var __timer = false;    // If modal hasnt change since last setup timer
  var __timeoutFn;

  var clearTimer = function() {
    __timer = false;
    clearTimeout(__timeoutFn);
  };

  var fitModal = function() {
    if (this.element.is(':visible')){
      var calculatedHeight = window.innerHeight;
      calculatedHeight -= $('.modal-header').outerHeight();
      calculatedHeight -= $('.modal-footer').outerHeight();
      calculatedHeight -= parseInt($('.modal-dialog').css('margin-top').replace('px', ''));
      calculatedHeight -= parseInt($('.modal-dialog').css('margin-bottom').replace('px', ''));
      $('.modal-body.modal-fitscreen').css('overflow', 'auto');
      $('.modal-body.modal-fitscreen').css('max-height', calculatedHeight);
    }
  };

  // Show user's tikcets
  var showTicketsModal = function(betslips) {
    clearTimer();
    this.loading = false;
    this.element.modal('hide');
    this.element.html(BS.templates.modal({
      betslips: true,
      model: betslips
    }));
    this.element.modal('show');
  };

  // Show a ticket with it's details
  var showTicketDetailModal = function(user, betslips, betslipIndex) {
    clearTimer();
    this.loading = false;
    this.element.modal('hide');
    this.element.html(BS.templates.modal({
      betslip: true,
      index: betslipIndex,
      model: betslips,
      user: user,
    }));
    this.element.modal('show');
  };

  // Show uesr's transactions
  var showTransactions = function(transactions) {
    clearTimer();
    this.loading = false;
    this.element.modal('hide');
    this.element.html(BS.templates.modal({
      transactions: true,
      model: transactions
    }));
    this.element.modal('show');
  };

  // Default modal to confirm or show some messages
  var showDefault = function(message, overrideButtons, buttons) {

    var btns = [{ label: gettext('Close') }];
    if (buttons instanceof Array) {
      
      if (overrideButtons)
        btns = buttons;
      else 
        btns.concat(buttons);
    }
    clearTimer();
    this.loading = false;
    this.element.modal('hide');
    this.element.html(BS.templates.modal({
      default: true,
      message: message, 
      buttons: btns
    }));
    this.element.modal('show');
    $(".sp-button-sec[data-dismiss='modal']").focus();
    $(".sp-button[data-dismiss='modal']").focus();
  };

  // Modal with inputs
  var showInputModal = function(message, inputs, overrideButtons, buttons, submit) { //message, cancel, confirm, confirmFunc, input1, input2, input3, input4, input5) {

    var btns = [{ label: gettext('Close') }];
    if (buttons instanceof Array) {
      
      if (overrideButtons)
        btns = buttons;
      else 
        btns.concat(buttons);
    }
    clearTimer();
    this.loading = false;
    this.element.modal('hide');
    this.element.html(BS.templates.modal({
      default: true,
      message: message, 
      inputs: inputs,
      buttons: btns,
      submit: submit
    }));
    this.element.modal('show');
  };

  // Loading modal
  var showLoading = function(show, message) {

    clearTimer();
    if (show) {
      this.loading = true;
      this.element.html(BS.templates.modal({ loading: true, message: message }));
      this.element.modal('show');
    } else if (this.loading) {
      this.loading = false;
      this.element.modal('hide');
    }
  };

  // Statistics window
  var showStats = function(src, modalStyle, containerStyle, iframeStyle) {
    clearTimer();
    this.loading = false;
    this.element.modal('hide');
    this.element.html(BS.templates.modal({
      stats: true,
      src: src,
      modalStyle: modalStyle,
      containerStyle: containerStyle,
      iframeStyle: iframeStyle
    }));
    this.element.modal('show');
  };

  var showLetter = function(content) {
    switch(content){
    case 'rules':
    case 'contact':
    case 'faq':
    case 'policies':
    case 'responsibility':
    case 'terms':
      __getDocument(content);
      break;
    default:
     console.error('Not implemented modal.showLetter(\'' + content + '\')');
     return;
    }
    // var title = '';
    // var context = {};
    // switch(content){
    // case 'policies':
    //   if(global_settings.flavor != null)
    //     content = content + '_' + global_settings.lang_code;
    //   title = gettext('Privacy Policy');
    //   break;
    // case 'responsibility':
    //   content = content + '_' + global_settings.lang_code;
    //   title = gettext('Responsible Gaming');
    //   break;
    // case 'rules':
    //   content = content + '_' + global_settings.lang_code;
    //   context = server_settings;
    //   title = gettext('Bet Rules');
    //   break;
    // case 'terms':
    //   if(global_settings.flavor != null)
    //     content = content + '_' + global_settings.lang_code;
    //   title = gettext('Terms and Conditions');
    //   break;
    // case 'faq':
    //   title = gettext('FAQ');
    //   if(global_settings.flavor == 'MZ')
    //     content = content + '_mz';
    //   content = content + '_' + global_settings.lang_code;
    //   break;
    // case 'contact':
    //   title = gettext('Contact');
    //   if(global_settings.flavor == 'MZ')
    //     content = content + '_mz';
    //   content = content + '_' + global_settings.lang_code;
    //   break;
    // default:
    //  console.error('Not implemented modal.showLetter(\'' + content + '\')');
    //  return;
    // }
    // content = 'letter_'+content;
    // this.element.modal('hide');
    // this.element.html(BS.templates.modal({
    //   letter: true,
    //   title: title,
    //   content: BS.templates[content](context)
    // }));
    // this.element.modal('show');
    // setTimeout(this.fitModal, 1);
  };

  var showLetterCustom = function(title, html) {
  
    this.element.modal('hide');
    this.element.html(BS.templates.modal({
      letterCustom: true,
      title: title,
      content: html
    }));
    this.element.modal('show');
    setTimeout(this.fitModal, 1);
  };

  var showBonusTerms = function(bonusType, context) {

    this.element.modal('hide');
    this.element.html(BS.templates.modal({
      letter: true,
      title: gettext('Bonus Terms'),
      content: BS.templates['bterms_' + bonusType + '_' + global_settings.lang_code](context)
    }));
    this.element.modal('show');
    setTimeout(this.fitModal, 1);
  };

  // Modal will be closed in the given time if it does not change
  var timer = function(time) {
    clearTimer();
    __timer = true;
    __timeoutFn = setTimeout(function() {
      if(__timer) {
        BS.base.modal.element.html('');
        BS.base.modal.element.modal('hide');
      }
    }, time);
  };

  var inpage = function(title, msg_title, msg, inputs, buttons, submit) {
    // BetUI.changeCurrent({ pane: 'modal' });
    BS.base.current.pane = 'modal';
    $('main').html(BS.templates.modal_inpage({

      title: title,
      message_title: msg_title,
      message: msg,
      inputs: inputs,
      buttons: buttons,
      submit: submit
    }));
  };
  
  var showCustom = function(html) {

    clearTimer();
    this.loading = false;
    this.element.modal('hide');
    this.element.html(BS.templates.modal({
      custom: true,
      html: html
    }));
    this.element.modal('show');
  };

  var showHtml = function(html) {

    clearTimer();
    this.loading = false;
    this.element.modal('hide');
    this.element.html(BS.templates.modal({
      pureHtml: true,
      html: html
    }));
    this.element.modal('show');
  };

  var showPrizeTable = function(contentHtml) {

    clearTimer();
    this.loading = false;
    this.element.modal('hide');
    this.element.html(BS.templates.modal({
      prizeTable: true,
      content: contentHtml
    }));
    this.element.modal('show');
  };

  var hide = function() {
    clearTimer();
    this.loading = false;
    this.element.modal('hide');
  };

  return {
    init: init,
    showLetter: showLetter,
    showLetterCustom: showLetterCustom,
    showBonusTerms: showBonusTerms,
    showLoading: showLoading,
    showDefault: showDefault,
    showCustom: showCustom,
    showHtml: showHtml,
    showPrizeTable: showPrizeTable,
    showTransactions: showTransactions,
    timer: timer,
    inpage: inpage,
    showStats: showStats,
    showInputModal: showInputModal,
    hide: hide,
  };
})();
