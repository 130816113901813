(function () {

  var super_ready = BS.base.ready;
  var ready = function() {
    super_ready();

    BS.base.breadcrumbs.addHandler('rules', function() {
      var node = [{
        name: Handlebars.helpers.capitalize(
          global_settings.panes.filter(function(p) { return p.paneId == 'rules'; })[0].paneName
        ),
        onclick: 'BS.base.ui.changePane(\'rules\')'
      }];
      return {node: node};
    });

    BS.base.ui.addComponent(BS.base.rules.init());

    BS.base.initStyle();
  };

  /**
   * Position logic of dropdown menus
   * @param {HTMLElemet} element - element the menu should follow
   * @param {string} menu_id - dropdown menu id
   * @param {number} param1 - vertical offset
   * @param {number} param2 - horizontal offset
   * @param {string} param3 - alignment with the element (default: align menu left and top (below element) )
   */
  var dropdown = function (element, menu_id, param1, param2, param3) {
    var e = $(element);
    var menu  = $("#"+menu_id);
    var list  = $("#"+menu_id+">ul");
    var toppy, lefty;

    menu.height(Math.max($(document).height(), $( window ).height()));

    menu.addClass("active");

    var ajuste = [param1, param2, param3];
    for(var i = ajuste.length - 1; i >= 0; i--)
      if(typeof ajuste[i] === 'undefined')
        ajuste.splice(i, 1);
      else
        break;

    if (ajuste.length != 3)
      ajuste = [0, 0, ''];
    else
      ajuste = ajuste.map( function(item, index) {
        if (index != 2)
          return (typeof(item) != 'number')?0:item;
        else
          return typeof(item) != 'string'?'':item;
      });

    if (ajuste[2].indexOf('bottom') >= 0)
      toppy = e.offset().top - list[0].offsetHeight - ajuste[0];
    else
      toppy = e.offset().top + e.outerHeight() + ajuste[0];

    if (ajuste[2].indexOf('right') >= 0)
      lefty =  e.offset().left + e.outerWidth() - list[0].offsetWidth - ajuste[1];
    else
      lefty = e.offset().left + ajuste[1];

    list.css({ top: toppy, left: lefty });
  };

  var __saveStyle = function() {
    
    Cookies('user_base_theme', global_settings.base_themes.current);
    if (BS.base.user.checkLogin()) {

      $.ajax({
        type: 'POST',
        url: '/base/theme/',
        data: { theme: global_settings.base_themes.current },
        success: function(data) {},
        error: function(data) { console.error(data); },
        headers: {'X-CSRFToken': BS.base.csrftoken}
      });
    }
  }

  /**
   * Set the first theme or the theme that has been saved
   * @param {string} style - initialize style, priority order: server over cookie over first style
   */
  var initStyle = function() {
    
    setStyle(global_settings.base_themes.current?global_settings.base_themes.current:Cookies('user_base_theme'));
  };

  /**
   * Change current theme (change base.<theme name>.css)
   * @param {string} style - style name (must be in global_settings.base_themes.choices)
   */
  var setStyle = function(style) {

    global_settings.base_themes.current = global_settings.base_themes.choices.indexOf(style) != -1?style:global_settings.base_themes.choices[0];
    $('link[title="base"]').attr('href', 'static/css/base.' + global_settings.base_themes.current + '.css');
    __saveStyle();
    BS.base.ui.update(['BASE-THEME']);
  };

  return Object.assign(BS.base, {
    ready: ready,
    dropdown: dropdown,
    initStyle: initStyle,
    setStyle: setStyle
  });
})();
