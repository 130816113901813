(function() {
  var init = function(init_pane) {
    $("html").css("padding-bottom", "calc(" + $("footer").height() + "px + 2rem)");

    this.update();
    setTimeout(function() {
      if (typeof(init_pane) === 'undefined') {
        BS.base.ui.changePane(global_settings.panes[0].paneId);
      } else {
        BS.base.ui.changePane(init_pane);
      }
    }, 0);
  };

  var addComponent = function(callback) {
    updateCallbacks.add(callback);
  };

  var update = function(msgs) {
    updateCallbacks.fire(msgs, {}, {});
  };

  var changePane = function(pane) {
    BS.base.current.pane = pane;

    currentPaneInfo = global_settings.panes.find(function(p) {
      return p.paneId == pane;
    });
    template_name = currentPaneInfo.template;
    template_name = template_name.replace("%(LANG)", global_settings.lang_code);

    var context;
    if (currentPaneInfo.context) {
      context = currentPaneInfo.context.split("|").reduce(function(acc, val) {
        return acc[val];
      }, window);
    }

    $("main").replaceHtml(BS.templates[template_name](context));

    this.update(["BASE_PANE"]);
  };

  var recoverUser = function() {
    BS.base.modal.showInputModal(
      // New
      gettext("Recover username"),
      [{ id: "recover_email", label: gettext("Email:"), type: "email", name: "email" }],
      true,
      [{ label: gettext("Cancel") }, { label: gettext("Confirm"), gold: true, submit: true }],
      'BS.base.logic.resetUsername($("#recover_email").val());'
    );
  };

  var recoverPassword = function() {
    BS.base.modal.showInputModal(
      // New
      gettext("Recover password"),
      [{ id: "recover_email", label: gettext("Email:"), type: "email", name: "email" }],
      true,
      [{ label: gettext("Cancel") }, { label: gettext("Confirm"), gold: true, submit: true }],
      'BS.base.logic.resetPassword($("#recover_email").val());'
    );
  };

  var updateCallbacks = $.Callbacks();

  return {
    init: init,
    addComponent: addComponent,
    update: update,
    changePane: changePane,
    recoverUser: recoverUser,
    recoverPassword: recoverPassword
  };
})();
