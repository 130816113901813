(function() {

  var __state = {
    hasContent: false,
    content: ''
  };

  var __getRules = function() {

    $.ajax({
      type: 'GET',
      url: global_settings.apis.documents + 'rules', // global_settings.documents['rules'],
      headers: { 'X-CSRFToken': BS.base.csrftoken },
      timeout: 3000,
      success: function(data) {
  
        __state.content = data;
        __state.hasContent = true;
        
      },
      error: function(data) {
        
        __state.content = gettext('Fail to communicate with server');
        __state.hasContent = false;
      },
      complete: function(data) {

        __render();
      }
    });
  };

  var __render = function(checkState) {

    if (checkState && !__state.hasContent) {

      __getRules();
      return;
    }

    $('#rules-content').replaceHtml(__state.content);
  };

  var init = function() { return BS.base.rules.update; };
  var update = function(msgs) {

    if (typeof msgs === 'undefined'){
    } else if (msgs instanceof Array){

      if (msgs[0] == 'BASE_PANE' && BS.base.current.pane == 'rules'){
        
        __render(true);
      }
    }
  };


  return {
    init: init,
    update: update
  };
})();