(function () {
  
  var __render = function() {

    var html = '';
    if (typeof __handlers[BS.base.current.pane] === 'function')
      html = BS.templates.breadcrumbs(__handlers[BS.base.current.pane]());
    $('#breadcrumbs').replaceHtml(html);
  };

  var update = __render;

  var addHandler = function(paneId, func){
    __handlers[paneId] = func;
  };

  var __handlers = {};

  return {
    update: update,
    addHandler: addHandler,
  };
})();
