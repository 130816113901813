(function() {

  // Calculate the number of combinations
  // n: number of elements
  // r: length of a combination
  var nCk = function(n, k) {
    k = Math.min(n - k, k);
  
    N = Array.apply(null, {length: k}).map(function(e, i) { return i + (n - k) + 1; }).reduce(function(a, b) { return a * b; }, 1);
    D = Array.apply(null, {length: k}).map(function(e, i) { return i + 1; }).reduce(function(a, b) { return a * b; }, 1);
    return N/D;
  };

  // Randomly generate a sequence
  // n: numbers of sequence between 1 and n
  // r: size of this sequence
  var generateSequence = function(n, r) {
  
    var resp = [];
    for(var i = 1; i <= n; resp.push(i++));
    for(var x = resp.length, y, z; x; y = parseInt(Math.random() * x), z = resp[--x], resp[x] = resp[y], resp[y] = z);
    return resp.slice(0, r);
  };

  var moneyFormatInput = function(input) {

    var sep = '.'
    input = input.toString().replace(/\D/g, '');
    switch(global_settings.flavor){
    case 'PY':
      input = input.replace(/^.*$/g, '0$&');
      break;
    default:
      input = input.replace(/^.*$/g, '00$&').replace(/\d{2}$/g, sep + '$&');
      break;
    }
    return parseFloat(input);
  };

  var WEEKDAYS = [
    gettext('Sunday'), 
    gettext('Monday'), 
    gettext('Tuesday'), 
    gettext('Wednesday'), 
    gettext('Thursday'), 
    gettext('Friday'), 
    gettext('Saturday')
  ];

  var MONTHS = [
    gettext('January'), 
    gettext('February'), 
    gettext('March'), 
    gettext('April'), 
    gettext('May'), 
    gettext('June'), 
    gettext('July'),
    gettext('August'),
    gettext('September'),
    gettext('October'),
    gettext('November'),
    gettext('December')
  ];

  return {
    nCk: nCk,
    generateSequence: generateSequence,
    moneyFormatInput: moneyFormatInput,
    WEEKDAYS: WEEKDAYS,
    MONTHS: MONTHS
  }
})();