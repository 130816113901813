(function() {

  var __common_changePassword = BS.base.user.changePassword;
  var changePassword = function() {

    var old_pass = $('#old_password').val();
    var new_pass1 = $('#new_password').val();
    var new_pass2 = $('#new_password2').val();

    if(new_pass1 != new_pass2) {

      BS.base.modal.showDefault(gettext("Passwords do not match!"));
      return;
    }
    __common_changePassword(old_pass, new_pass1,
      function(data) { },
      function(data) { },
      function(data) { }
    );
  };

  var changePasswordModal = function() {

    BS.base.modal.showInputModal(
      gettext('Change password'),
      [
        { 
          id:  'old_password',
          label: gettext('Old password'),
          type: 'password'
        },
        { 
          id:  'new_password',
          label: gettext('New password'),
          type: 'password'
        },
        { 
          id:  'new_password2',
          label: gettext('Confirm password'),
          type: 'password'
        }
      ],
      true,
      [
        { label: gettext('Cancel') },
        { label: gettext('Confirm'), gold:  true, submit: true  }
      ],
      'BS.base.user.changePassword();'
    );
  };

  // Call modal to confirm if user wants to reprint last ticket
  var showCheckReprint = function() {

    BS.base.modal.showInputModal(
      gettext('Confirm reprint?'),
      [
        { 
          id:  'reprint_password',
          label: gettext('Password'),
          type: 'password'
        }
      ],
      true,
      [
        { label: gettext('Cancel') },
        { label: gettext('Confirm'), gold:  true, submit: true  }
      ],
      'BS.base.user.requestReprint($(\'#reprint_password\').val());'
    );
  };

  // Call modal for reset balance confirmation 
  var showConfirmZeroBalance = function() {
    BS.base.modal.showDefault( // New
      gettext("Confirm reset balance to zero?"),
      true,
      [
        { label: gettext("Cancel") },
        { func: "BS.base.user.requestZeroBalance();", label: gettext("Confirm"), gold: true }
      ]
    );
  };

  var showSettings = function() {

    BS.base.modal.showHtml(BS.templates.modal_settings());
  };

  return Object.assign(BS.base.user, {
    changePassword: changePassword,
    changePasswordModal: changePasswordModal,
    showCheckReprint: showCheckReprint,
    showConfirmZeroBalance: showConfirmZeroBalance,
    showSettings: showSettings
  });
})();