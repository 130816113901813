(function(){

  var init = function(dev) {
    __development = dev;
    
    (function pollUser(initial){
      var userUrl = "/user/status";
      if(!initial)
        userUrl += "?update";
      $.ajax({
        url: userUrl,
        success: function(data){
          if(__development) { console.log('pollUser:', data); }
          if (typeof data.action !== 'undefined' && data.action == 'logout'){

            BS.base.state.user = { anon: true };
            BS.base.modal.showDefault( data.msg, true, [
              { label: gettext('Close'), func: 'location.reload();' }
            ]);
            BS.base.ui.update(["USER"]);
          } else {

            BS.base.state.user = data.user;
            BS.base.state.dServerTime = new Date(data.servertime).getTime() - Date.now();
            BS.base.state.utcoffset = data.utcoffset;
            BS.base.ui.update(["USER"]);
          }
          if (typeof data.messages !== 'undefined'){
              msgs = ""
              data.messages.forEach(m => msgs += "<p>" + m[1] + "</p>")
              BS.base.modal.showDefault(msgs);
          }

          if(!BS.base.state.user.anon)
            pollUser();
        },
        error: function(error) {
          console.error(error);
          pollUser(true);
        },
        dataType: "json",
      });
    })(true);
  };

  var login = function(username, password) {
    $.ajax({
      type: 'POST',
      url: '/login/',
      data: {
        username: username,
        password: password
      },
      success: function(data) {
        location.reload();
      },
      error: function(data) {
        console.error(data);
        if (typeof(data.responseJSON) !== 'undefined' && typeof(data.responseJSON.error) !== 'undefined'){

          // state.msg = gettext(data.responseJSON.error);
          // stateCallbacks.fire(state, ["MSG"]);
          BS.base.modal.showDefault(gettext(data.responseJSON.error));
        } else {

          // state.msg = gettext('Fail to communicate with server');
          // stateCallbacks.fire(state, ["MSG"]);
          BS.base.modal.showDefault(gettext('Fail to communicate with server'));
        }
      },
      headers: {'X-CSRFToken': BS.base.csrftoken}
    });
  };

  var resetUsername = function(email) {
    
    $.ajax({
      type: 'POST',
      url: '/user/recoverusername/',
      data: { 'email': email },
      success: function(data) {

        BS.base.modal.showDefault(data.msg);
      },
      error: function(data) {
        
        console.error(data);
        if (typeof data.responseJSON !== 'undefined')
          BS.base.modal.showDefault(data.responseJSON.error);
      },
      headers: { 'X-CSRFToken': BS.base.csrftoken }
    }); 
  };

  var resetPassword = function(email) {
    
      $.ajax({
        type: 'POST',
        url: '/user/recoverpassword/',
        data: { 'email': email },
        success: function(data) {

          BS.base.modal.showDefault(data.msg);
        },
        error: function(data) {
          
          console.error(data);
          if (typeof data.responseJSON !== 'undefined')
            BS.base.modal.showDefault(data.responseJSON.error);
        },
        headers: { 'X-CSRFToken': BS.base.csrftoken }
      }); 
  };

  var requestUser = function() {};

  var __development = false;

  return {
    init: init,
    login: login,
    resetUsername: resetUsername,
    resetPassword: resetPassword
  };
})();
