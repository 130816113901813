(function () {
  var init = function () {
      return update
  }

  var render = function () {
    var units = BS.homepage.state.publicities.betslip

    $('#betslip-publicity').replaceHtml(BS.templates.betslip_publicity({ publicities: units }))
  }

  var update = function (msgs) {
      render()

      $("#betslip_panel").on("detach", function() { $('#betslip-publicity').hide() });
      $("#betslip_panel").on("attach", function() { $('#betslip-publicity').show() });
  }

  return {
    init: init,
    update: update,
    render: render
  }
})()
