(function() {

  var _request_transferTo = function(dest, value, successCallback, errorCallback, completeCallback) {
    
    successCallback = (typeof(successCallback) == 'function')?successCallback:function(){};
    errorCallback = (typeof(errorCallback) == 'function')?errorCallback:function(){};
    completeCallback = (typeof(completeCallback) == 'function')?completeCallback:function(){};

    BS.base.modal.showLoading(true);
    $.ajax({
      type: 'POST',
      url: '/transfer/',
      data: { 'value': value, 'system': dest },
      headers: { 'X-CSRFToken': BS.base.csrftoken },
      success: function(data) {

        BS.base.modal.showDefault(data.message);
        successCallback(data);
      },
      error: function(data) {
        
        console.error(data);
        if (typeof data.responseJSON !== 'undefined' && typeof data.responseJSON.message !== 'undefined')
          BS.base.modal.showDefault(data.responseJSON.message);
        else
          BS.base.modal.showDefault(data.responseText);
        errorCallback(data);
      },
      complete: function(data) { completeCallback(data) }
    }); 
  };

  return {
    _request_transferTo: _request_transferTo
  };
})();