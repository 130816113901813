(function() {
  var __render = function() {
    var menu = [];

    if (typeof BS.base.state.user !== "undefined" && typeof BS.base.state.user.type !== "undefined") {
      menu = menu.concat(__menu[BS.base.state.user.type]);
      menu = menu.concat(__DEFAULT_MENU[BS.base.state.user.type]);
    }

    $("#user-dropdown").replaceHtml(
      BS.templates.user_menu({
        user: BS.base.state.user,
        menu: menu
      })
    );
  };

  var update = __render;

  var addItem = function(userType, position, menuItem) {
    // __menu[userType].splice(position, 0, menuItem);
    if (userType in __menu) {
      menuItem.position = position;
      __menu[userType].push(menuItem);
      __menu[userType].sort(function(item_1, item_2) {
        return item_1.position - item_2.position;
      });
    }
  };

  var __menu = {
    WU: [],
    BI: [],
    PC: [],
    VI: [],
    KI: []
  };
  var __DEFAULT_MENU = {
    WU: [
      { name: gettext("Settings"), func: "BS.base.user.showSettings();", mobileOnly: true },
      { name: gettext("Transactions"), func: "BS.base.user_transactions.get();" },
      { name: gettext("Change Password"), func: "BS.base.user.changePasswordModal();" },
      { name: gettext("Log off"), func: "BS.base.user.logout();" }
    ],
    BI: [{ name: gettext("Reprint Last"), func: "BS.base.user.showCheckReprint();" }, { name: gettext("Zero Balance"), func: "BS.base.user.showConfirmZeroBalance();" }],
    PC: [{ name: gettext("Reprint Last"), func: "BS.base.user.showCheckReprint();" }, { name: gettext("Log off"), func: "BS.base.user.logout();" }],
    VI: [{ name: gettext("Reprint Last"), func: "BS.base.user.showCheckReprint();" }],
    KI: [{ name: gettext("Zero Balance"), func: "BS.base.user.showConfirmZeroBalance();" }, { name: gettext("Log off"), func: "BS.base.user.logout();" }]
  };

  return {
    update: update,
    addItem: addItem
  };
})();
